import React, { Component } from "react";
import axios from "axios";
import RecipeItems from "./RecipeItems";
export class Recipes extends Component {
  state = {
    recipes: [],
    isLoaded: false,
  };
  componentDidMount() {
    axios
      .get("https://wordpress.superdev.space/wp-json/wp/v2/recipes")
      .then((res) =>
        this.setState({
          recipes: res.data,
          isLoaded: true,
        })
      )
      .catch((err) => console.log(err));
  }
  render() {
    const { recipes, isLoaded } = this.state;
    return (
      <div>
        {recipes.map((recipe) => (
          <RecipeItems key={recipe.id} recipe={recipe} />
        ))}
      </div>
    );
  }
}
export default Recipes;
