import React from 'react';
import logo from './logo.svg';
import './App.css';

import Recipes from './components/Recipe';

function App() {

return (

<div className='App'>

<Recipes/>

</div>

);

}

export default App;