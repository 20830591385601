import React, { Component } from 'react'
import PropTypes from 'prop-types';
import axios from 'axios';
export class RecipeItems extends Component {
   state = {
       imgUrl: '',
       isLoaded: false
   }
   static propTypes = {
       recipe: PropTypes.object.isRequired
   }
   componentDidMount () {
       const {featured_media, author} = this.props.recipe;
       const getImageUrl = axios.get(`https://wordpress.superdev.space/wp-json/wp/v2/media/${featured_media}`);
       
      
       Promise.all([getImageUrl]).then(res => {
           console.log(res);
           this.setState({
               imgUrl: res[0].data.media_details.sizes.full.source_url,
               isLoaded: true
           });
       });
    }
   render() {
       const { title, content } = this.props.recipe;
       const { imgUrl, isLoaded} = this.state;
       return (
           <div>
              <h2>{title.rendered}</h2>
              <img src={imgUrl} alt={title.rendered}/>              
              <div dangerouslySetInnerHTML={{__html: content.rendered}}></div>
           </div>
       )
   }
}

export default RecipeItems;